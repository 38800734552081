import React from "react";

import Layout from "../../global-components/layout/layout";
import Button from "../../global-components/button/button";
import Header from "../../global-components/header/header";
import styles from "./index.module.css";

class CancelSubscription extends React.Component {
  constructor() {
    super();

    this.state = {
      cancellationProcessed: false,
      cancellationProcessing: false,
      subscriptionId: null
    };
  }

  componentDidMount() {
    const search = new URLSearchParams(window.location.search);
    const subscriptionId = atob(
      decodeURIComponent(search.get("subscriptionId"))
    );

    this.setState({
      subscriptionId: subscriptionId
    });
  }

  cancelSubscription = async event => {
    event.preventDefault();

    this.setState({
      cancellationProcessing: true
    });

    const cancelSubscriptionResponse = await fetch("/subscriptions/", {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        subscriptionId: this.state.subscriptionId
      })
    });
    const response = await cancelSubscriptionResponse.json();

    if (response?.cancellationStatus) {
      this.setState({
        cancellationProcessing: false,
        cancellationProcessed: true,
        cancellationStatus: response.cancellationStatus
      });
    }
  };

  render() {
    const {
      cancellationProcessing,
      cancellationProcessed,
      cancellationStatus
    } = this.state;

    return (
      <div className={styles.cancelSubscriptionPage}>
        <Layout>
          <Header config={null} />

          <div className={styles.cancelSubscriptionContentBox}>
            {!cancellationProcessed && (
              <>
                <div>Are you sure you want to cancel your subscription?</div>

                {cancellationProcessing ? (
                  <div className={styles.loader}></div>
                ) : (
                  <div>
                    <Button onClick={this.cancelSubscription}>
                      Cancel Subscription
                    </Button>
                  </div>
                )}
              </>
            )}

            {cancellationProcessed && (
              <>
                {cancellationStatus === "successfully_cancelled" && (
                  <div>
                    Your subscription has successfully been cancelled. You
                    should receive an email confirming this cancellation.
                  </div>
                )}

                {cancellationStatus === "previously_cancelled" && (
                  <div>
                    Your subscription has already been cancelled. No further
                    action is required.
                  </div>
                )}
              </>
            )}
          </div>
        </Layout>
      </div>
    );
  }
}

export default CancelSubscription;
