import React, { Component } from "react";
import Button from "../button/button";
import styles from "./eula-modal.module.css";

class EulaModal extends Component {
  render() {
    return (
      <div className={styles.eulaContainer}>
        <div className={styles.eulaModal}>
          <div className={styles.eulaHeading}>
            <h2>End User Licence Agreement</h2>
          </div>

          {this._renderEulaText()}

          <div className={styles.eulaClose}>
            <Button color="navy" onClick={this.props.onEulaClose}>
              Close
            </Button>
          </div>
        </div>
      </div>
    );
  }

  _renderEulaText() {
    return (
      <p
        className={styles.eulaText}
        dangerouslySetInnerHTML={{
          __html: `
<b>END USER LICENSE AGREEMENT (EULA) FOR RESELLER PARTNER CUSTOMERS</b>

<b>IMPORTANT NOTICE: PLEASE READ CAREFULLY BEFORE PROCEEDING:</b> This end user licence agreement (EULA) is a legal agreement between you (Customer or you) and Orpheus Cyber Limited (Orpheus) incorporated and registered in England and Wales with company number 10499131 whose registered office is at 34 Lime Street, London EC3M 7AT for the use of the subscription services provided by Orpheus via an authorised reseller to you under this EULA via <b>https://Orpheus-cyber.com </b>or any other website notified to the Client by Orpheus from time to time, as more particularly described in the Documentation. 

BY CHECKING ON THE “ACCEPT” BOX YOU AGREE TO THE TERMS OF THIS EULA WHICH WILL BIND YOU AND YOUR EMPLOYEES. IF YOU DO NOT AGREE TO THE TERMS OF THIS EULA, WE ARE UNWILLING TO PROVIDE YOU WITH ACCESS TO THE SERVICES. IN THIS CASE YOU MUST RETURN ANY CUSTOMER CODE AND ANY ACCOMPANYING DOCUMENTATION TO THE RESELLER FROM WHOM YOU PURCHASED THE SERVICES. 

<b>AGREED TERMS </b>

<b>1. INTERPRETATION </b>

<b>1.1 </b>The definitions and rules of interpretation in this clause apply in this EULA. 

<b>(a) Agreed Terms: </b>The agreement made between the Customer and the Authorised Reseller for the provision of the Services. 

<b>(b) Analytical Data: </b>the data provided to the Customer via the Services and in accordance with the Documentation detailing the Authorised Users use of the Services. 

<b>(c) Authorised Reseller: </b>a person authorised by Orpheus to resell and distribute the Services 

<b>(d) Authorised Users: </b>those employees, agents and independent contractors of the Customer who are authorised by the Customer to use the Services and the Documentation, as further described in clause 2.2(d). 

<b>(e) Business Day: </b>a day other than a Saturday, Sunday or public holiday in England when banks in London are open for business. 

<b>(f) Complaint: </b>a complaint or request relating to either party’s obligations under Data Protection Laws relevant to this Agreement, including any compensation claim from a Data Subject or any notice, investigation or other action from a Supervisory Authority; 

<b>(g) Confidential Information: </b>information that is proprietary or confidential and is either clearly labelled as such or identified as Confidential Information in clause 7.6. 

<b>(h) Customer Code: </b>the unique reference number or link specified by Orpheus and provided to the Customer by the Authorised Reseller which allows a Customer to access the Service. 

<b>(i) Customer Data: </b>the data inputted by the Customer, Authorised Users, or an Authorised Reseller on the Customer's behalf for the purpose of using the Services or facilitating the Customer's use of the Services. 

<b>(j) Data Controller</b>: has the meaning set out in the Data Protection Laws; 

<b>(k) Data Processor</b>: has the meaning given to that term (or to the term ‘processor’) in the Data Protection Laws; 

<b>(l) Data Protection Laws</b>: 

(i) the Data Protection Act 1998 and the Privacy and Electronic Communications (EC Directive) Regulations 2003, SI 2003/2426, and any laws or regulations implementing Directive 95/46/EC (Data Protection Directive) or Directive 2002/58/EC (ePrivacy Directive); and/or 

(ii) the General Data Protection Regulation (EU) 2016/679 (GDPR), once applicable, and/or any corresponding or equivalent United Kingdom national laws or regulations (Revised UK DP Law); 

(iii) and, in either case any judicial or administrative interpretation of any of the above, any guidance, guidelines, codes of practice, approved codes of conduct or approved certification mechanisms issued by any relevant Supervisory Authority;

<b>(m) Data Subject</b>: has the meaning set out in the Data Protection Laws; 

<b>(n) Data Subject Request</b>: a request made by a Data Subject to exercise any rights of Data Subjects under Data Protection Laws; 

<b>(o) Documentation: </b>the document made available to the Customer by Orpheus online via <b>https://Orpheus-cyber.com</b> or such other web address notified by Orpheus to the Customer from time to time which sets out a description of the Services and the user instructions for the Services; 

<b>(p) Personal Data</b>: has the meaning given to that term in the Data Protection Laws and relates only to personal data, or any part of such personal data, in respect of which the Customer is the Data Controller and in relation to which Orpheus is providing services under this Agreement (but does not, in particular, include personal data provided by an Authorised User or the Customer to a third party acting in the capacity of a data controller); 

<b>(q) Services: </b>the subscription services provided by Orpheus to the Customer under this EULA via <b>https://Orpheus-cyber.com</b> or any other website notified to the Customer by Orpheus from time to time, as more particularly described in the Documentation;

<b>(r) Software: </b>the online software applications provided by Orpheus as part of the Services;

<b>(s) Subscription Term: </b>The Subscription Term agreed between the Customer and an Authorised Reseller;

<b>(t) Supervisory Authority</b>: any local, national or multinational agency, department, official, parliament, public or statutory person or any government or professional body, regulatory or supervisory authority, board or other body responsible for administering Data Protection Laws;

<b>(u) User Subscriptions: </b>the user subscriptions purchased by the Customer from an Authorised Reseller which entitle Authorised Users to access and use the Services and the Documentation in accordance with this EULA;

<b>(v) Virus: </b>any thing or device (including any software, code, file or programme) which may: prevent, impair or otherwise adversely affect the operation of any computer software, hardware or network, any telecommunications service, equipment or network or any other service or device; prevent, impair or otherwise adversely affect access to or the operation of any programme or data, including the reliability of any programme or data (whether by re-arranging, altering or erasing the programme or data in whole or part or otherwise); or adversely affect the user experience, including worms, trojan horses, viruses and other similar things or devices. 

<b>1.2 </b>Clause, schedule and paragraph headings shall not affect the interpretation of this EULA. 

<b>1.3 </b>A person includes an individual, corporate or unincorporated body (whether or not having separate legal personality) and that person's legal and personal representatives, successors or permitted assigns. 

<b>1.4 </b>A reference to a company shall include any company, corporation or other body corporate, wherever and however incorporated or established. 

<b>1.5 </b>Unless the context otherwise requires, words in the singular shall include the plural and in the plural shall include the singular. 

<b>1.6 </b>Unless the context otherwise requires, a reference to one gender shall include a reference to the other genders. 

<b>1.7 </b>A reference to a statute or statutory provision is a reference to it as it is in force as at the date of this EULA. 

<b>1.8 </b>A reference to a statute or statutory provision shall include all subordinate legislation made as at the date of this EULA under that statute or statutory provision. 

<b>1.9 </b>A reference to writing or written includes faxes but not e-mail. 

<b>1.10 </b>References to clauses and schedules are to the clauses and schedules of this EULA; references to paragraphs are to paragraphs of the relevant schedule to this EULA.

<b>1.11 </b>A reference to a <b>holding company </b>or a <b>subsidiary </b>means a holding company or a subsidiary (as the case may be) as defined in section 1159 of the Companies Act 2006. In the case of a limited liability partnership which is a subsidiary of a company or another limited liability partnership, section 1159 of the Companies Act 2006 shall be construed so that: (a) references in sections 1159(1)(a) and (c) to voting rights are to the members' rights to vote on all or substantially all matters which are decided by a vote of the members of the limited liability partnership; and (b) the reference in section 1159(1)(b) to the right to appoint or remove a majority of its board of directors is to the right to appoint or remove members holding a majority of the voting rights. 

<b>2. SERVICES </b>

<b>2.1 </b>Subject to the restrictions set out in this clause 2 and the other terms and conditions of this EULA, Orpheus hereby grants to the Customer a non- exclusive, non-transferable right to permit the Authorised Users to use the Services and the Documentation during the Subscription Term solely for the Customer's internal business operations. 

<b>2.2 </b>In relation to the Authorised Users, the Customer undertakes that: 

<b>(a) </b>the maximum number of Authorised Users that it authorises to access and use the Services and the Documentation shall not exceed the number of User Subscriptions it has purchased from time to time; 

<b>(b) </b>it will not allow or suffer any User Subscription to be used by more than one individual Authorised User; 

<b>(c) </b>each Authorised User shall keep a secure password for his use of the Services and Documentation, and that each Authorised User shall keep his password confidential; 

<b>(d) </b>it shall maintain a written, up to date list of current Authorised Users and provide such list to Orpheus or the Authorised Reseller through whom it purchases the Services within 5 Business Days of Orpheus's written request at any time or times; 

<b>(e) </b>it shall permit Orpheus to audit the Services in order to establish the name and password of each Authorised User. Such audit may be conducted no more than once per quarter, at Orpheus's expense, and this right shall be exercised with reasonable prior notice, in such a manner as not to substantially interfere with the Customer's normal conduct of business; and 

<b>(f) </b>if any of the audits referred to in clause 2.2(e) reveal that any password has been provided to any individual who is not an Authorised User, then without prejudice to Orpheus's other rights, the Customer shall promptly disable such passwords and Orpheus shall not issue any new passwords to any such individual. 

<b>2.3 </b>The Customer shall not: 

<b>(a) </b>except as may be allowed by any applicable law which is incapable of exclusion by agreement between the parties: 

<b>(i) </b>and except to the extent expressly permitted under this EULA, attempt to copy, modify, duplicate, create derivative works from, frame, mirror, republish, download, display, transmit, or distribute all or any portion of the Software and/or Documentation (as applicable) in any form or media or by any means; or 

<b>(ii) </b>attempt to reverse compile, disassemble, reverse engineer or otherwise reduce to human-perceivable form all or any part of the Software; or 

<b>(b) </b>access all or any part of the Services and Documentation in order to build a product or service which competes with the Services and/or the Documentation; or 

<b>(c) </b>use the Services and/or Documentation to provide services to third parties; or 

<b>(d) </b>subject to clause 13.1, license, sell, rent, lease, transfer, assign, distribute, display, disclose, or otherwise commercially exploit, or otherwise make the Services and/or Documentation available to any third party except the Authorised Users, or 

<b>(e) </b>attempt to obtain, or assist third parties in obtaining, access to the Services and/or Documentation, other than as provided under this clause 2; and 

<b>2.4 </b>The Customer shall use all reasonable endeavours to prevent any unauthorised access to, or use of, the Services and/or the Documentation and, in the event of any such unauthorised access or use, promptly notify the Authorised Reseller. 

<b>2.5 </b>The rights provided under this clause 2are granted to the Customer only and shall not be considered granted to any subsidiary or holding company of the Customer unless the Authorised Reseller agrees otherwise in writing. 

<b>2.6 </b>Orpheus shall, during the Subscription Term, provide the Services and make available the Documentation to the Customer on and subject to the terms of this EULA. 

<b>3. CUSTOMER DATA </b>

<b>3.1 </b>The Customer shall own all right, title and interest in and to all of the Customer Data and shall have sole responsibility for the legality, reliability, integrity, accuracy and quality of the Customer Data. 

<b>3.2 </b>Orpheus shall follow its archiving procedures for Customer Data and the Analytical Data as set out in its Back-Up Policy available at <b>https://Orpheus-cyber.com</b> or such other website address as may be notified to the Customer from time to time, as such document may be amended by Orpheus in its sole discretion from time to time. In the event of any loss or damage to Customer Data or Analytical Data, the Customer's sole and exclusive remedy shall be for Orpheus to use reasonable commercial endeavours to restore the lost or damaged Customer Data or Analytical Data from the latest back-up of such Customer Data or Analytical Data maintained by Orpheus in accordance with the archiving procedure described in its Back-Up Policy. Orpheus shall not be responsible for any loss, destruction, alteration or disclosure of Customer Data caused by any third party (except those third parties sub-contracted by Orpheus to perform services related to Customer Data maintenance and back- up). 

<b>3.3 </b>Orpheus shall, in providing the Services, comply with its Privacy Policy relating to the privacy of the Customer Data available at <b>https://Orpheus-cyber.com</b> or such other website address as may be notified to the Customer from time to time, as such document may be amended from time to time by Orpheus in its sole discretion. 

<b>3.4 </b>If Orpheus processes any Personal Data on the Customer's behalf when performing its obligations under this EULA, the parties record their intention that the Customer shall be the data controller and Orpheus shall be a data processor. 

<b>3.5 </b>Orpheus shall comply with all Data Protection Laws (which apply to it in its capacity as a data processor) in connection with the processing of Personal Data in respect of the delivery of the Services and the exercise and performance of its rights and obligations under this Agreement. 

<b>3.6 </b>The Customer shall comply with all Data Protection Laws (which apply to it in its capacity as a data controller) in connection with the processing of Personal Data in respect of the exercise and performance of its rights and obligations under this Agreement, and to enable Orpheus to deliver the Services. 

<b>3.7 </b>Instructions and details of processing - Insofar as Orpheus processes Personal Data on behalf of the Customer: 

<b>3.7.1 </b>unless required to do otherwise by applicable laws, Orpheus shall (and shall ensure each person acting under its authority shall) process the Personal Data only on and in accordance with the Customer’s documented instructions as set out in this clause 3 and Schedule 2 (Data Processing Details), and as updated from time to time by the written agreement of the parties (<b>Processing Instructions</b>); and 

<b>3.7.2 </b>if any applicable laws require it to process Personal Data other than in accordance with the Processing Instructions, Orpheus shall notify the Customer of any such requirement before processing the Personal Data (unless any of the applicable laws prohibit such information on important grounds of public interest). 

<b>3.8 </b>Technical and organisational measures - Orpheus shall implement and maintain, at its cost and expense (taking into account those factors which it is entitled to take into account pursuant to the Data Protection Laws) appropriate technical and organisational measures in relation to the processing of Personal Data by Orpheus: 

<b>3.8.1 </b>so as to ensure a level of security in respect of the Personal Data processed by it is appropriate to the risks that are presented by the processing, in particular from accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to Personal Data transmitted, stored or otherwise processed; and 

<b>3.8.2 </b>without prejudice to clause 3.11, insofar as is possible, to assist the Customer in the fulfilment of the Customer’s obligations to respond to Data Subject Requests relating to Personal Data. 

<b>3.9 </b>Using staff and other processors - Orpheus shall not engage another Data Processor for carrying out any processing activities in respect of the Personal Data without the Customer’s prior written consent. The Customer consents to the processing of Personal Data by the Authorised Reseller in accordance with the Data Processing Instructions. 

<b>3.10 </b>Orpheus shall ensure that all Orpheus personnel processing Personal Data: 

<b>3.10.1 </b>are subject to obligations of confidentiality which apply, generally or specifically, to the Personal Data; and 

<b>3.10.2 </b>are reliable and have received appropriate training on compliance with the Data Protection Laws. 

<b>3.11 </b>Assistance with Customer’s Compliance with Data Subject Rights - Orpheus shall: 

<b>3.11.1 </b>record and then refer all Data Subject Requests it receives to the Customer, without undue delay; 

<b>3.11.2 </b>provide such assistance to the Customer as the Customer reasonably requests in relation to a Data Subject Request; and 

<b>3.11.3 </b>not respond to any Data Subject Request or Complaint without the Customer’s prior written approval. 

<b>3.12 </b>Without prejudice to clause 3.11 Orpheus shall, at its cost and expense, provide such assistance to the Customer as the Customer reasonably requires (taking into account the nature of processing and the information available to Orpheus) in ensuring compliance with such obligations as apply to the Customer under Data Protection Laws, with respect to: 

<b>3.12.1 </b>security of processing; 

<b>3.12.2 </b>Data Protection Impact Assessments (as such term is defined in the Data Protection Laws); 

<b>3.12.3 </b>prior consultation with a Supervisory Authority regarding high risk processing. 

<b>3.13 </b>International Data Transfer 

<b>3.13.1 </b>Subject to clause 3.13.2, Orpheus shall not engage another Data Processor (Sub Processor) for carrying out any processing activities in respect of the Personal Data without the Client’s prior written consent; 

<b>3.13.2 </b>Subject to clause 3.13.3, and without prejudice to the generality of clause 3.13.1, the Customer consents to the appointment of Sub Processors in connection with certain tools used by Orpheus to deliver the Services, and for the purpose of some web hosting undertaken for Orpheus in connection with the Services and to the processing of Personal Data by each of such Sub Processors in accordance with the Data Processing Instructions. 

<b>3.13.3 </b>Orpheus may only transfer the Personal Data to the Sub Processors and permit the processing of Personal Data outside the EU under the following conditions: 

<b>(a) </b>the Personal Data is being processed in a territory which is subject to a current finding by the European Commission under the Data Protection Laws that the territory provides adequate protection for the privacy rights of individuals; or 

<b>(b) </b>Orpheus participates in a valid cross-border transfer mechanism under the Data Protection Laws, and has entered into an agreement with each Sub Processor which includes the European Commission's Standard Contractual Clauses for the transfer of Personal Data from the European Union to processors established in third countries, as set out in the Annex to Commission Decision 2010/87/EU 

<b>3.14 </b>Records, Information and Audit - Orpheus shall maintain complete, accurate and up to date written records of all categories of processing activities carried out on behalf of the Customer being: 

<b>3.14.1 </b>the name and contact details of the Data Processor(s) and (subject to the Customer providing such information) of each Data Controller on behalf of which the Data Processor is acting, and of Orpheus’s representative and data protection officer (if any); 

<b>3.14.2 </b>the categories of processing carried out on behalf of each Data Controller; 

<b>3.14.3 </b>where applicable, details of transfers of Personal Data to an International Recipient; and 

<b>3.14.4 </b>where possible, a general description of the technical and organisational security measures referred to in clause 3.8. 

<b>3.15 </b>Orpheus shall make available to the Customer on request in a timely manner copies of the records under clause 3.14. 

<b>3.16 </b>Subject to clause 3.22 Orpheus shall allow for and contribute to audits, including inspections, conducted by the Customers or another auditor mandated by the Customer, for the purpose of demonstrating compliance by Orpheus with its obligations under Data Protection Laws and under this clause 3. <b>3.17 </b>Breach notification - In respect of any Personal Data Breach, Orpheus shall: 

<b>3.17.1 </b>notify the Customer of the Personal Data Breach without undue delay; and 

<b>3.17.2 </b>provide the Customer without undue delay with such details as the Customer reasonably requires regarding: 

<b>(a) </b>the nature of the Personal Data Breach, including the categories and approximate numbers of Data Subjects and Personal Data records concerned; 

<b>(b) </b>the likely consequences of the Personal Data Breach; and 

<b>(c) </b>any measures taken, or that Orpheus recommends, to address the Personal Data Breach, including to mitigate its possible adverse effects, 

provided that, (without prejudice to the above obligations) if Orpheus cannot provide all these details without undue delay it shall provide the Customer with reasons for the delay and when it expects to be able to provide the relevant details (which may be phased), and give the Customer regular updates on these matters. 

<b>3.18 </b>Each party shall promptly inform the other party if it receives a Complaint and provide the Customer with full details of such Complaint. 

<b>3.19 </b>Deletion or return of Personal Data and copies - Orpheus shall without delay, at the Customer’s written request, either securely delete or securely return all the Personal Data to the Customer after the end of the provision of the relevant Services related to processing unless: 

<b>3.19.1 </b>storage of any data is required by applicable laws and, if so, Orpheus shall inform the Customer of any such requirement); or 

<b>3.19.2 </b>Orpheus requires storage of any data for the establishment, exercise or defence of legal claims. 

<b>3.20 </b>The Customer acknowledges that Orpheus is reliant on the Customer for direction as to the extent to which Orpheus is entitled to use and process the Personal Data. Consequently, Orpheus will not be liable for any claim brought by a Data Subject arising from any action or omission by Orpheus to the extent that such action or omission resulted from the Customer’s instructions or from the Customer’s failure to comply with Data Protection Laws or its obligations under this Agreement. 

<b>3.21 </b>Without prejudice to clause 3.5 the Customer shall: 

<b>3.21.1 </b>establish the legal basis under Data Protection Laws for the processing of the Personal Data by Orpheus and any Third-Party Providers for the delivery of the Services (including, in the absence of any other legal basis, all necessary consents); 

<b>3.21.2 </b>provide Orpheus with details of such legal basis. 

<b>3.22 </b>Each audit and inspection referred to in clause 3.16 shall be carried out: 

<b>3.22.1 </b>during normal business hours on at least 20 Business Days’ prior written notice to Orpheus and shall take no longer than two Business Days; 

<b>3.22.2 </b>not more than once in any twelve-month period; 

<b>3.22.3 </b>in a manner that is limited to that which is reasonably required to demonstrate compliance with Orpheus’s obligations under the Data Protection Laws and this clause 3, without access to Orpheus confidential information unrelated to this Agreement (including information relating to other customers of Orpheus; and 

<b>3.22.4 </b>In so far as reasonably possible, in a manner that minimises disruption to Orpheus’s business and the delivery of the Services. 

<b>3.23 </b>This clause 3 shall survive termination of the Agreement. 

<b>4. ORPHEUS’ OBLIGATIONS </b>

<b>4.1 </b>Orpheus undertakes that the Services will be performed substantially in accordance with the Documentation and with reasonable skill and care. 

<b>4.2 </b>The undertaking at clause 4.1 shall not apply to the extent of any non- conformance which is caused by use of the Services contrary to Orpheus's instructions, or modification or alteration of the Services by any party other than Orpheus or Orpheus's duly authorised contractors or agents. If the Services do not conform with the foregoing undertaking, Orpheus will, at its expense, use all reasonable commercial endeavours to correct any such non- conformance promptly, or provide the Customer with an alternative means of accomplishing the desired performance. Such correction or substitution constitutes the Customer's sole and exclusive remedy for any breach of the undertaking set out in clause 4.1. Notwithstanding the foregoing, Orpheus: 

<b>(a) </b>does not warrant that the Customer's use of the Services will be uninterrupted or error-free; or that the Services, Documentation and/or the information obtained by the Customer through the Services will meet the Customer's requirements; and 

<b>(b) </b>is not responsible for any delays, delivery failures, or any other loss or damage resulting from the transfer of data over communications networks and facilities, including the internet, and the Customer acknowledges that the Services and Documentation may be subject to limitations, delays and other problems inherent in the use of such communications facilities. 

<b>5. CUSTOMER'S OBLIGATIONS </b>

<b>5.1 </b>The Customer shall: 

<b>(a) </b>comply with all applicable laws and regulations with respect to its activities under this EULA; 

<b>(b) </b>ensure that the Authorised Users use the Services and the Documentation in accordance with the terms and conditions of this EULA and shall be responsible for any Authorised User's breach of this EULA; 

<b>(c) </b>obtain and shall maintain all necessary licences, consents, and permissions necessary for Orpheus, its contractors and agents to perform their obligations under this EULA, including without limitation the Services; 

<b>(d) </b>ensure that its network and systems comply with the relevant specifications provided by Orpheus from time to time; and 

<b>(e) </b>be solely responsible for procuring and maintaining its network connections and telecommunications links from its systems to Orpheus's data centres, and all problems, conditions, delays, delivery failures and all other loss or damage arising from or relating to the Customer's network connections or telecommunications links or caused by the internet. 

<b>6. PROPRIETARY RIGHTS </b>

<b>6.1 </b>The Customer acknowledges and agrees that Orpheus and/or its licensors own all intellectual property rights in the Services and the Documentation. Except as expressly stated herein, this EULA does not grant the Customer any rights to, or in, patents, copyright, database right, trade secrets, trade names, trade marks (whether registered or unregistered), or any other rights or licences in respect of the Services or the Documentation. 

<b>6.2 </b>Orpheus confirms that it has all the rights in relation to the Services and the Documentation that are necessary to grant all the rights it purports to grant under, and in accordance with, the terms of this EULA. 

<b>7. CONFIDENTIALITY </b>

<b>7.1 </b>Each party may be given access to Confidential Information from the other party in order to perform its obligations under this EULA. A party's Confidential Information shall not be deemed to include information that: 

<b>(a) </b>is or becomes publicly known other than through any act or omission of the receiving party; 

<b>(b) </b>was in the other party's lawful possession before the disclosure; 

<b>(c) </b>is lawfully disclosed to the receiving party by a third party without restriction on disclosure; 

<b>(d) </b>is independently developed by the receiving party, which independent development can be shown by written evidence; or 

<b>(e) </b>is required to be disclosed by law, by any court of competent jurisdiction or by any regulatory or administrative body. 

<b>7.2 </b>Each party shall hold the other's Confidential Information in confidence and, unless required by law or as permitted by clause 7.3, not make the other's Confidential Information available to any third party, or use the other's Confidential Information for any purpose other than the implementation of this EULA. 

<b>7.3 </b>Orpheus shall be permitted to share the following limited information with the Authorised Reseller from whom the Customer purchased the Services: Customer name, account status (active/inactive); number of Authorised Users; overview performance statistics; expiry date of current subscription term. 

<b>7.4 </b>Each party shall take all reasonable steps to ensure that the other's Confidential Information to which it has access is not disclosed or distributed by its employees or agents in violation of the terms of this EULA. 

<b>7.5 </b>Neither party shall be responsible for any loss, destruction, alteration or disclosure of Confidential Information caused by any third party. 

<b>7.6 </b>The Customer acknowledges that details of the Services, and the results of any performance tests of the Services, constitute Orpheus's Confidential Information. 

<b>7.7 </b>Orpheus acknowledges that the Customer Data is the Confidential Information of the Customer. 

<b>7.8 </b>This clause 7 shall survive termination of this EULA, however arising. 

<b>7.9 </b>No party shall make, or permit any person to make, any public announcement concerning this EULA without the prior written consent of the other parties (such consent not to be unreasonably withheld or delayed), except as required by law, any governmental or regulatory authority (including, without limitation, any relevant securities exchange), any court or other authority of competent jurisdiction. 

<b>8. INDEMNITY </b>

<b>8.1 </b>Orpheus shall defend the Customer, its officers, directors and employees against any claim that the Services or Documentation infringes any United Kingdom patent effective as of the date that the Customer first accesses the Services, copyright, trade mark, database right or right of confidentiality, and shall indemnify the Customer for any amounts awarded against the Customer in judgment or settlement of such claims, provided that: 

<b>(a) </b>Orpheus is given prompt notice of any such claim; 

<b>(b) </b>the Customer provides reasonable co-operation to Orpheus in the defence and settlement of such claim, at Orpheus's expense; and 

<b>(c) </b>Orpheus is given sole authority to defend or settle the claim. 

<b>8.2 </b>In the defence or settlement of any claim, Orpheus may procure the right for the Customer to continue using the Services, replace or modify the Services so that they become non-infringing or, if such remedies are not reasonably available, terminate this EULA on 2 Business Days' notice to the Customer without any additional liability or obligation to pay liquidated damages or other additional costs to the Customer. 

<b>8.3 </b>In no event shall Orpheus, its employees, agents and sub-contractors be liable to the Customer to the extent that the alleged infringement is based on: 

<b>(a) </b>a modification of the Services or Documentation by anyone other than Orpheus; or 

<b>(b) </b>the Customer's use of the Services or Documentation in a manner contrary to the instructions given to the Customer by Orpheus; or 

<b>(c) </b>the Customer's use of the Services or Documentation after notice of the alleged or actual infringement from Orpheus or any appropriate authority. 

<b>8.4 </b>The foregoing states the Customer's sole and exclusive rights and remedies, and Orpheus's (including Orpheus's employees', agents' and sub- contractors') entire obligations and liability, for infringement of any patent, copyright, trade mark, database right or right of confidentiality. 

<b>9. LIMITATION OF LIABILITY </b>

<b>9.1 </b>This clause 9 sets out the entire financial liability of Orpheus (including any liability for the acts or omissions of its employees, agents and sub-contractors) to the Customer: 

<b>(a) </b>arising under or in connection with this EULA; 

<b>(b) </b>in respect of any use made by the Customer of the Services and Documentation or any part of them; and 

<b>(c) </b>in respect of any representation, statement or tortious act or omission (including negligence) arising under or in connection with this EULA. 

<b>9.2 </b>Except as expressly and specifically provided in this EULA: 

<b>(a) </b>the Customer assumes sole responsibility for results obtained from the use of the Services and the Documentation by the Customer, and for conclusions drawn from such use; 

<b>(b) </b>all warranties, representations, conditions and all other terms of any kind whatsoever implied by statute or common law are, to the fullest extent permitted by applicable law, excluded from this EULA; and 

<b>(c) </b>the Services and the Documentation are provided to the Customer on an "as is" basis. 

<b>9.3 </b>Nothing in this EULA excludes the liability of Orpheus: 

<b>(a) </b>for death or personal injury caused by Orpheus's negligence; or 

<b>(b) </b>for fraud or fraudulent misrepresentation. 

<b>9.4 </b>Subject to clause 9.2 and clause 9.3: 

<b>(a) </b>Orpheus shall not be liable whether in tort (including for negligence or breach of statutory duty), contract, misrepresentation, restitution or otherwise for any loss of profits, loss of business, depletion of goodwill and/or similar losses or loss or corruption of data or information, or pure economic loss, or for any special, indirect or consequential loss, costs, damages, charges or expenses however arising under this EULA; and 

<b>(b) </b>Orpheus's total aggregate liability in contract tort (including negligence or breach of statutory duty), misrepresentation, restitution or otherwise, arising in connection with the performance or contemplated performance of this EULA shall be limited to the total subscription fees paid by the Authorised Reseller to Orpheus for the User Subscriptions during the 12 months immediately preceding the date on which the claim arose. 

<b>10. TERM AND TERMINATION </b>

<b>10.1 </b>This EULA shall, unless otherwise terminated as provided in this clause 10, commence on the date agreed between the Customer and the Authorised Reseller and will terminate when the Agreed Terms terminate or expire. 

<b>10.2 </b>Without affecting any other right or remedy available to it, either party may terminate this EULA with immediate effect by giving written notice to the other party if: 

<b>(a) </b>the other party fails to pay any amount due under this EULA on the due date for payment and remains in default not less than 20 days after being notified in writing to make such payment; 

<b>(b) </b>the other party commits a material breach of any other term of this EULA which breach is irremediable or (if such breach is remediable) fails to remedy that breach within a period of 30 days after being notified in writing to do so; 

<b>(c) </b>the other party commences negotiations with all or any class of its creditors with a view to rescheduling any of its debts, or makes a proposal for or enters into any compromise or arrangement with its creditors other than for the sole purpose of a scheme for a solvent amalgamation of that other party with one or more other companies or the solvent reconstruction of that other party; 

<b>(d) </b>a petition is filed, a notice is given, a resolution is passed, or an order is made, for or in connection with the winding up of that other party other than for the sole purpose of a scheme for a solvent amalgamation of that other party with one or more other companies or the solvent reconstruction of that other party; 

<b>(e) </b>an application is made to court, or an order is made, for the appointment of an administrator, or if a notice of intention to appoint an administrator is given or if an administrator is appointed, over the other party; 

<b>(f) </b>the holder of a qualifying floating charge over the assets of that other party has become entitled to appoint or has appointed an administrative receiver; 

<b>(g) </b>a person becomes entitled to appoint a receiver over the assets of the other party or a receiver is appointed over the assets of the other party; 

<b>(h) </b>a creditor or encumbrancer of the other party attaches or takes possession of, or a distress, execution, sequestration or other such process is levied or enforced on or sued against, the whole or any part of the other party's assets and such attachment or process is not discharged within 14 days; 

<b>(i) </b>any event occurs, or proceeding is taken, with respect to the other party in any jurisdiction to which it is subject that has an effect equivalent or similar to any of the events mentioned in clause 10.2(c) to clause 10.2(h) (inclusive). 

<b>10.3 </b>On termination of this EULA for any reason: 

<b>(a) </b>all licences granted under this EULA shall immediately terminate; 

<b>(b) </b>Orpheus may terminate any licence granted to Authorised Users in connection with the use of a mobile application as part of the Services; 

<b>(c) </b>each party shall return and make no further use of any equipment, property, Documentation and other items (and all copies of them) belonging to the other party; 

<b>(d) </b>Orpheus may destroy or otherwise dispose of any of the Customer Data and the Analytical Data in its possession unless Orpheus receives, no later than ten days after the effective date of the termination of this EULA, a written request for the delivery to the Customer of the then most recent back-up of the Customer Data and Analytical Data. Orpheus shall use reasonable commercial endeavours to deliver the back-up to the Customer within 30 days of its receipt of such a written request, provided that the Customer has, at that time, paid all fees and charges outstanding at and resulting from termination (whether or not due at the date of termination). The Customer shall pay all reasonable expenses incurred by Orpheus in returning or disposing of Customer Data and Analytical Data or providing the Customer with a back-up copy of such data; and 

<b>(e) </b>any rights, remedies, obligations or liabilities of the parties that have accrued up to the date of termination, including the right to claim damages in respect of any breach of the agreement which existed at or before the date of termination shall not be affected or prejudiced. 

<b>11. FORCE MAJEURE </b>

Orpheus shall have no liability to the Customer under this EULA if it is prevented from or delayed in performing its obligations under this EULA, or from carrying on its business, by acts, events, omissions or accidents beyond its reasonable control, including, without limitation, strikes, lock-outs or other industrial disputes (whether involving the workforce of Orpheus or any other party), failure of a utility service or transport or telecommunications network, act of God, war, riot, civil commotion, malicious damage, compliance with any law or governmental order, rule, regulation or direction, accident, breakdown of plant or machinery, fire, flood, storm or default of suppliers or sub- contractors, provided that the Customer is notified of such an event and its expected duration. 

<b>12. GENERAL </b>

<b>12.1 Conflict. </b>If there is an inconsistency between any of the provisions in the Agreed Terms, the main body of this EULA and the Schedule, the provisions shall take precedence in the order stated in this clause 15.1. 

<b>12.2 Variation. </b>Orpheus shall be entitled to vary the terms of this EULA by giving the Customer 30 days’ notice by email. No other variation of this EULA shall be effective unless it is in writing and signed by the parties (or their authorised representatives). The administrator of the Customer's account shall be deemed to be an authorised representative of the Customer. 

<b>12.3 Waiver. </b>No failure or delay by a party to exercise any right or remedy provided under this EULA or by law shall constitute a waiver of that or any other right or remedy, nor shall it prevent or restrict the further exercise of that or any other right or remedy. No single or partial exercise of such right or remedy shall prevent or restrict the further exercise of that or any other right or remedy. 

<b>12.4 Rights and Remedies. </b>Except as expressly provided in this EULA, the rights and remedies provided under this EULA are in addition to, and not exclusive of, any rights or remedies provided by law. 

<b>12.5 Severance. </b>If any provision (or part of a provision) of this EULA is found by any court or administrative body of competent jurisdiction to be invalid, unenforceable or illegal, the other provisions shall remain in force. 

<b>12.6 </b>If any invalid, unenforceable or illegal provision would be valid, enforceable or legal if some part of it were deleted, the provision shall apply with whatever modification is necessary to give effect to the commercial intention of the parties. 

<b>12.7 Entire Agreement. </b>This EULA, and any documents referred to in it, constitute the whole agreement between the parties and supersede any previous arrangement, understanding or agreement between them relating to the subject matter they cover. 

<b>12.8 </b>Each of the parties acknowledges and agrees that in entering into this EULA it does not rely on any undertaking, promise, assurance, statement, representation, warranty or understanding (whether in writing or not) of any person (whether party to this EULA or not) relating to the subject matter of this EULA, other than as expressly set out in this EULA. 

<b>12.9 No Partnership or Agency. </b>Nothing in this EULA is intended to or shall operate to create a partnership between the parties, or authorise either party to act as agent for the other, and neither party shall have the authority to act in the name or on behalf of or otherwise to bind the other in any way (including, but not limited to, the making of any representation or warranty, the assumption of any obligation or liability and the exercise of any right or power). 

<b>12.10 Third Party Rights. </b>This EULA does not confer any rights on any person or party (other than the parties to this EULA and, where applicable, their successors and permitted assigns) pursuant to the Contracts (Rights of Third Parties) Act 1999. 

<b>13. ASSIGNMENT </b>

<b>13.1 </b>The Customer shall not, without the prior written consent of Orpheus (which shall not be unreasonable withheld or delayed), assign, transfer, charge, sub-contract or deal in any other manner with all or any of its rights or obligations under this EULA. 

<b>13.2 </b>Orpheus may at any time assign, transfer, charge, sub-contract or deal in any other manner with all or any of its rights or obligations under this EULA. 

<b>14. NOTICES </b>

<b>14.1 </b>Any notice required to be given under this EULA shall be in writing and shall be delivered by hand or sent by pre-paid first-class post or recorded delivery post or email to the other party at its address set out in this EULA, the customer portal at <b>https://Orpheus-cyber.com</b> or such other address as may have been notified by that party for such purposes. 

<b>14.2 </b>A notice delivered by hand shall be deemed to have been received when delivered (or if delivery is not in business hours, at 9 am on the first business day following delivery). A correctly addressed notice sent by pre-paid first- class post or recorded delivery post shall be deemed to have been received at the time at which it would have been delivered in the normal course of post. A notice sent by email shall be deemed to have been received when sent. 

<b>15. GOVERNING LAW AND JURISDICTION </b>

<b>15.1 </b>This EULA and any dispute or claim arising out of or in connection with it or its subject matter or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the law of England and Wales. 

<b>15.2 </b>Each party irrevocably agrees that the courts of England and Wales shall have exclusive jurisdiction to settle any dispute or claim arising out of or in connection with this EULA or its subject matter or formation (including non- contractual disputes or claims). 

<b>THIS AGREEMENT </b>has been entered into on the date stated at the beginning of it. 

<b>END OF MAIN DOCUMENT</b>

<b>SCHEDULE 1 – SERVICE LEVEL AGREEMENT </b>

<b>1. INTERPRETATION </b>

The following definitions and rules of interpretation apply in this schedule. 

<b>1.1 </b>Definitions: 

<b>Commercially Reasonable Efforts: </b>the same degree of priority and diligence with which Orpheus meets the support needs of its other similar customers. 

<b>Customer Cause: </b>any of the following causes: 

<b>(a) </b>any improper use, misuse or unauthorised alteration of the Software or Services by the Customer; 

<b>(b) </b>any use of the Software or Services by the Customer in a manner inconsistent with the then-current Documents; and 

<b>(c) </b>outages or disruptions to the Service caused by the Customer. 

<b>Fault: </b>any failure of the Services to operate in all material respects in accordance with the Documentation, including any failure or error referred to in the Service Level Table. 

<b>Help Desk Support: </b>any support provided by help desk technicians sufficiently qualified and experienced to identify and resolve most support issues relating to the Services. 

<b>Main Agreement: </b>the agreement to which this schedule relates. 

<b>Out-of-scope Services: </b>any services provided by Orpheus in connection with any apparent problem regarding the Services reasonably determined by Orpheus not to have been caused by a Fault, but rather by a Customer Cause or a cause outside Orpheus's control (including any investigational work resulting in such a determination). 

<b>Service Levels: </b>the service levels set out in paragraph 5.1. 

<b>Solution: </b>either of the following outcomes: 

<b>(a) </b>correction of a Fault; or 

<b>(b) </b>a workaround in relation to a Fault (including a reversal of any changes to the Software and/or Services if deemed appropriate by Orpheus) that is reasonably acceptable to the Customer. 

<b>Support Request: </b>request made by the Customer in accordance with this schedule for support in relation to the Services, including correction of a Fault. 

<b>Support Services: </b>Maintenance of the Software and providing Help Desk Support but excluding any Out-of-scope Services. 

<b>1.2 </b>All initial capitalised terms in this schedule shall have the meaning given to them in the Main Agreement. 

<b>2. SUPPORT SERVICES </b>

<b>2.1 </b>During the Subscription Term Orpheus shall perform the Support Services during the Normal Working Hours in accordance with the Service Levels. 

<b>2.2 </b>As part of the Support Services, Orpheus shall: 

<b>(a) </b>provide Help Desk Support by means of the following e-mail address helpdesk@Orpheus.com and by means of the help desk support page; 

<b>(b) </b>use Commercially Reasonable Efforts to correct all Faults notified under paragraph (a); and 

<b>(c) </b>provide technical support for the Software and the Services in accordance with the Service Levels. 

<b>2.3 </b>Orpheus shall carry out planned maintenance outside of the Core Hours; and 

<b>2.4 </b>Orpheus may reasonably determine that any services are Out-of-scope Services. If Orpheus makes any such determination, it shall promptly notify the Customer of that determination. 

<b>2.5 </b>The Customer acknowledges that Orpheus is not obliged to provide Out-of- scope Services. 

<b>3. FEES </b>

<b>3.1 </b>The provision of Support Services on a remote (via email), off-site basis within the Subscription Term shall be included in the fees you pay to our Authorised Reseller.

<b>3.2 </b>The provision of Support Services outside the Subscription Term or at the Customer’s premises or the provision of Out-of-scope Services shall be charged at the time and materials rates agreed between the parties when the Out-of-Scope Services are requested. 

<b>4. SUBMITTING SUPPORT REQUESTS AND ACCESS </b>

<b>4.1 </b>The Customer may request Support Services by way of a Support Request made via email by completing the support request form on the help desk support page. 

<b>4.2 </b>Each Support Request shall include a description of the problem and the start time of the incident. 

<b>4.3 </b>The Customer shall provide Orpheus with: 

<b>(a) </b>prompt notice of any Faults; and 

<b>(b) </b>such output and other data, documents, information, assistance and (subject to compliance with all Customer's security and encryption requirements notified to Orpheus in writing) remote access to the Customer System, as are reasonably necessary to assist Orpheus to reproduce operating conditions similar to those present when the Customer detected the relevant Fault and to respond to the relevant Support Request. 

<b>4.4 </b>All Support Services shall be provided remotely by Orpheus. 

<b>5. SERVICE LEVELS </b>

<b>Service Availability and Maintenance </b>

<b>5.1 </b>Orpheus shall use commercially reasonable endeavours to make the Services available 97% of the time during the Core Hours, except for unscheduled maintenance performed during the Core Hours, provided that Orpheus has used reasonable endeavours to give the Customer at least 3 Core Hours’ notice in advance. 

<b>Support </b>

<b>5.2 </b>Orpheus shall: 

<b>(a) </b>prioritise all Support Requests based on its reasonable assessment of the severity level of the problem reported; and 

<b>(b) </b>respond to all Support Requests within the response times specified in the table set out below by acknowledging receipt of the Support Request and commencing Commercially Reasonable Efforts to achieve a Solution: 

<b>Severity level of Fault </b>
<b>Definition</b>
<b>Service Level response time*</b>

<b>1</b>
<b>Fatal: </b>An error in, or failure of, the Services such that the Services are unavailable to all Authorised Users 

4 Normal Working Hours 

<b>2</b>
<b>Severe: </b>An error in, or failure of, the Services with more than 25% of Authorised Users or critical functions affected but which is not a Fatal Fault. Use of Services is intermittent.

12 Normal Working Hours

<b>3</b>
<b>Medium: </b>An error in, or failure of, the Services: 
a) that affects between more than 10% number of Authorised Users but which is not a Fatal or Severe Fault; and/or 
b) that affects a limited number of functions, but the Services can still be used. 

24 Normal Working Hours

<b>4</b>
<b>Minor: </b>An error in, or failure of, the Services that affects less than 10% of Authorised Users. The Service can still be used. 

3 Business Days

*For the purposes of this table, where a Support Request is received outside Normal Working Hours, it shall be deemed to have been received upon the commencement of the next Normal Working Hour. 

<b>5.3 </b>The parties may, on a case-by-case basis, agree in writing to a reasonable extension of the Service Level response times. 

<b>5.4 </b>Orpheus shall give the Customer regular updates of the nature and status of its efforts to correct any Fault. 

<b>5.5 </b>All Support Requests shall be received and responded to in English. 

<b>6. ESCALATION </b>

<b>6.1 </b>If the Customer is not satisfied with the response or the response time, the Customer may escalate the Support Request to the parties' respective Relationship Managers. 

<b>7. COMMUNICATIONS </b>

<b>7.1 </b>In addition to the mechanisms for giving notice specified in clause 17 of the Main Agreement, the parties may communicate in respect of any matter referred to in this agreement by e-mail (unless specified otherwise). 

<b>SCHEDULE 2 - DATA PROTECTION – DATA PROCESSING DETAILS </b>

<b>Subject matter of processing </b>

Orpheus is providing the Services to the Customer through a cyber threat intelligence and cyber risk rating platform which provides Authorised Users a range of raw and processed information in order to enhance cyber security. 

<b>Duration of Processing </b>

Personal Data will be processed for the duration of this Agreement.

<b>Nature and Purpose of Processing </b>

Orpheus will process the Personal Data in order to identify and authenticate Authorised Users and give the Customer and Authorised Users access to the Orpheus platform.

<b>Types of Personal Data to be Processed </b>

Data of Authorised Users to be processed will be: 
• Name 
• Business e-mail address 
• Personal e-mail address (if shared)

<b>Categories of Data Subjects </b>

The Data Subjects will be employees, agents and independent contractors of the Customer authorised to use the Services.

<b>Transfers of Personal Data to a country outside EU/international organisation </b>

Some third party tools - such as Google Analytics - used by Orpheus to deliver the Services involve personal data being processed in the USA. This is only done under the legally binding personal data protection terms of EU-US Privacy Shield Agreement. 

<b>END OF SCHEDULE </b>`
        }}
      />
    );
  }
}

export default EulaModal;
