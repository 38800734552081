import React from "react";

import Layout from "../../global-components/layout/layout";
import Header from "../../global-components/header/header";
import Basket from "../../global-components/checkout/basket";
import Error404 from "../../global-components/errors/error-404";
import Hero from "./components/hero";
import ExampleReport from "./components/example-report";
import Faq from "./components/faq";
import styles from "./index.module.css";
import LinkedInTag  from "react-linkedin-insight";

import { getConfig } from "../../utils";

class CrrReportCheckout extends React.Component {
  constructor() {
    super();

    this.state = {
      config: null,
      partnershipExists: undefined
    };
  }

  async componentDidMount() {
    const pathname = window.location.pathname;
    const product = "crr-report";
    // let partnership = pathname.replace(product, "").replace(/\//g, "");

    // ====================================================
    // This is temporary: virtualstock are using edge4health.orpheus-cyber.com...
    // The above should be used after VS change to the new structure
    let partnership;
    if (this.props.partnership && this.props.partnership === "edge4health") {
      partnership = "edge4health";
    } else {
      partnership = pathname.replace(product, "").replace(/\//g, "");
    }

    // ====================================================

    const config = await getConfig(
      product,
      partnership,
      this.props.customer.companyId
    );
    if (config) {
      this.setState({
        partnershipExists: true,
        config: config
      });
    } else {
      this.setState({
        partnershipExists: false
      });
    }
  }

  render() {
    const { config, partnershipExists } = this.state;
    const { customer } = this.props;

    if (partnershipExists === undefined || !config) {
      return null;
    }

    if (!partnershipExists) {
      return <Error404 />;
    }

    if (config?.content?.trackingIds?.linkedIn) {
      LinkedInTag.init(config.content.trackingIds.linkedIn.partnerId);
      LinkedInTag.track();
    }

    return (
      <>
        <div className={styles.landingPageContent}>
          <Layout>
            <Header config={config} />
            <Hero config={config} />
            <ExampleReport config={config} />
            <Faq config={config} />
          </Layout>
        </div>

        <div className={styles.landingPageCheckout}>
          <Basket customer={{ ...customer }} config={config} />
        </div>
      </>
    );
  }
}

export default CrrReportCheckout;
