const content = {
  header: {
    partnershipAltTxt: "Beroe",
    partnershipLogoFileName: "beroe.png",
    partnershipUrl: "https://www.beroeinc.com/"
  },
  basket: {
    subTitle: "Subscribe now to get a 30% Beroe partnership exclusive discount."
  },
  checkoutComplete: {
    success: {
      secondaryButtonText: "Visit Beroe Advantage Procurement",
      secondaryButtonLink: "https://www.beroeinc.com/"
    }
  },
  landing: {
    hero: {
      title: [
        "Beroe has partnered with Orpheus to deliver Cyber Risk Rating Assessments to companies like yours."
      ],
      content: [
        "Orpheus is the only UK government-accredited cyber threat intelligence company providing award-winning cyber risk rating services.",
        "Beroe is the world’s leading provider of procurement intelligence, supplier intelligence, risk and compliance."
      ],
      contentsub: [
        "Orpheus' award-winning Cyber Risk Rating reports will enable you to improve your cyber security and demonstrate to customers how seriously you take cyber security and compliance with cyber security regulations. You will receive:"
      ],
      resources: [
        {
          content:
            "Securing your Supply Chain with Threat-led Cyber Risk Ratings",
          ctaText: "Read our White Paper",
          ctaUrl:
            "https://orpheus-cyber.com/wp-content/uploads/2020/03/Orpheus-SupplyChain-Whitepaper.pdf"
        },
        {
          content:
            "Read our latest press coverage in SC Magazine on securing your supply chain",
          ctaText: "Read the Article",
          ctaUrl:
            "https://www.scmagazineuk.com/search/articles?keywords=orpheus&headlinesOnly=False"
        }
      ]
    },
    faq: {
      questions: [
        {},
        {
          question: "Who can I contact to find out more?",
          answer:
            "For more information, or if you would like to contact us directly, please send an email to",
          url: "mailto:compliance.support@beroe-inc.com ",
          urlText: "compliance.support@beroe-inc.com "
        }
      ]
    }
  }
};

export default content;
