import React, { Component } from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import serialize from "form-serialize";

import Wrap from "../layout/wrap";
import Button from "../button/button";
import CheckoutComplete from "./checkoutComplete";
import formStyles from "./form-elements.module.css";
import styles from "./checkout.module.css";
import orpheusLogo from "./images/orpheus-logo.svg";
import backButton from "./images/back-button.png";
import countryCodes from "./country-codes.json";

const stripeStyles = {
  base: {
    fontSize: "16px",
    color: "#fff",
    fontFamily: `'Roboto', sans-serif`,
    lineHeight: "2.5em",
    "::placeholder": {
      color: "rgba(255, 255, 255, 0.4)"
    },
    height: "2.5em",
    iconColor: "#fff"
  }
};

class CheckoutForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentIntentSecret: null,
      cardElement: null,
      cardCompleted: false,
      paymentProcessing: false,
      checkoutCompleted: false,
      checkoutSucceeded: false,
      checkoutError: false
    };

    window.dataLayer.push({
      event: "checkout",
      category: 'payment_funnel'
    });
  }

  async componentDidMount() {
    const { config } = this.props;

    if (!config?.settings?.isSubscription) {
      const response = await fetch("/payment-intents", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          settings: config.settings,
          customerDetails: this.props.customer
        })
      });
      const { paymentIntentSecret } = await response.json();

      this.setState({ paymentIntentSecret });
    }
  }

  getCardElement = cardElement => {
    this.setState({ cardElement });
  };

  cardChanged = ({ complete }) => {
    this.setState({
      cardCompleted: complete
    });
  };

  handleCheckoutBack = () => {
    this.props.onCheckoutBack();
  };

  handleCheckoutCompleted = checkoutStatus => {
    this.setState({
      checkoutCompleted: true
    });

    if (checkoutStatus === "success") {
      window.dataLayer.push({
        event: "confirmationSuccess",
        category: 'payment_funnel'
      });

      this.setState({
        checkoutSucceeded: true
      });
    } else if (checkoutStatus === "fail") {
      window.dataLayer.push({
        event: "confirmationFail",
        category: 'payment_funnel'
      });

      this.setState({
        checkoutSucceeded: false
      });
    } else if (checkoutStatus === "error") {
      window.dataLayer.push({
        event: "confirmationError",
        category: 'payment_funnel'
      });

      this.setState({
        checkoutError: true
      });
    }
  };

  handleCheckoutSubmit = async event => {
    event.preventDefault();

    const { config } = this.props;

    const details = serialize(event.target, { hash: true });

    this.setState({ paymentProcessing: true });

    if (config?.settings?.isSubscription) {
      await this.handleCreateSubscription(details);
    } else {
      await this.handlePayment(details);
    }
  };

  handleCreateSubscription = async details => {
    const { cardElement } = this.state;
    const { config } = this.props;
    let paymentMethod;

    try {
      paymentMethod = await this.props.stripe.createPaymentMethod(
        "card",
        cardElement,
        {
          billing_details: {
            name: details.name,
            email: details.email,
            address: {
              line1: details.line1,
              line2: details.line2,
              city: details.city,
              country: details.country
            }
          }
        }
      );

      if (paymentMethod.error) {
        this.handleCheckoutCompleted("fail");
      }
    } catch (e) {
      this.handleCheckoutCompleted("error");
    }

    // Create a subscription
    try {
      const subscriptionResponse = await fetch("/subscriptions", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          settings: config.settings,
          customerDetails: this.props.customer,
          stripeCustomerPaymentMethod: paymentMethod.paymentMethod.id
        })
      });
      const subscription = await subscriptionResponse.json();

      // Handle card errors and authentication/authorisation
      if (subscription.error) {
        this.handleCheckoutCompleted("fail");
      } else if (subscription.status === "incomplete") {
        const {
          client_secret,
          status
        } = subscription.latest_invoice.payment_intent;

        if (status === "requires_action") {
          this.props.stripe.confirmCardPayment(client_secret).then(result => {
            if (result.error) {
              this.handleCheckoutCompleted("fail");
            } else {
              this.handleCheckoutCompleted("success");
            }
          });
        } else if (status === "requires_payment_method") {
          // Collect new payment method
        }
      } else if (
        subscription.status === "trialing" &&
        subscription.pending_setup_intent
      ) {
        const { client_secret, status } = subscription.pending_setup_intent;

        if (status === "requires_action") {
          this.props.stripe.confirmCardSetup(client_secret).then(result => {
            if (result.error) {
              this.handleCheckoutCompleted("fail");
            } else {
              this.handleCheckoutCompleted("success");
            }
          });
        } else if (status === "requires_payment_method") {
          // Collect new payment method
        }
      } else {
        this.handleCheckoutCompleted("success");
      }
    } catch (e) {
      this.handleCheckoutCompleted("error");
    }
  };

  handlePayment = async details => {
    const { paymentIntentSecret, cardElement } = this.state;
    let stripePayment;

    try {
      stripePayment = await this.props.stripe.handleCardPayment(
        paymentIntentSecret,
        cardElement,
        {
          receipt_email: details.email,
          payment_method_data: {
            billing_details: {
              name: details.name,
              email: details.email,
              address: {
                line1: details.line1,
                line2: details.line2,
                city: details.city,
                country: details.country
              }
            }
          }
        }
      );
    } catch (error) {
      this.handleCheckoutCompleted("error");
    }

    if (stripePayment.error) {
      this.handleCheckoutCompleted("fail");
    } else {
      this.handleCheckoutCompleted("success");
    }
  };

  render() {
    const { name, email, country } = this.props.customer;
    const { config } = this.props;
    const {
      cardCompleted,
      paymentProcessing,
      checkoutCompleted,
      checkoutSucceeded,
      checkoutError
    } = this.state;

    if (checkoutCompleted) {
      return (
        <CheckoutComplete
          success={checkoutSucceeded}
          error={checkoutError}
          config={config}
        />
      );
    }

    return (
      <div className={styles.checkout}>
        <Wrap>
          <div className={styles.header}>
            <img
              className={styles.orpheusLogo}
              src={orpheusLogo}
              alt="Orpheus Logo"
            />
          </div>

          <div className={styles.backButtonContainer}>
            <img
              className={styles.backButton}
              onClick={this.handleCheckoutBack}
              src={backButton}
              alt="Checkout Back Button"
            />
          </div>

          <form onSubmit={this.handleCheckoutSubmit}>
            <p>Billing Information</p>
            <div className={formStyles.formGroup}>
              <div className={formStyles.element}>
                <label className={formStyles.label}>Name</label>
                <input
                  className={formStyles.input}
                  name="name"
                  defaultValue={name}
                  placeholder="Jane Doe"
                  required
                />
              </div>
              <div className={formStyles.element}>
                <label className={formStyles.label}>Email</label>
                <input
                  className={formStyles.input}
                  type="email"
                  name="email"
                  defaultValue={email}
                  placeholder="jane.doe@company.com"
                  required
                />
              </div>
              <div className={formStyles.element}>
                <label className={formStyles.label}>Address line 1</label>
                <input
                  className={formStyles.input}
                  name="line1"
                  defaultValue=""
                  placeholder="Flat 1"
                  required
                />
              </div>
              <div className={formStyles.element}>
                <label className={formStyles.label}>Address line 2</label>
                <input
                  className={formStyles.input}
                  name="line2"
                  defaultValue=""
                  placeholder="123 Heather Lane"
                />
              </div>
              <div className={formStyles.element}>
                <label className={formStyles.label}>City</label>
                <input
                  className={formStyles.input}
                  name="city"
                  defaultValue=""
                  placeholder="London"
                  required
                />
              </div>
              <div className={formStyles.element}>
                <label className={formStyles.label}>Country</label>
                <select
                  className={formStyles.input}
                  name="country"
                  defaultValue={country}
                  required
                  disabled
                >
                  {countryCodes.map(({ code, name }) => {
                    return (
                      <option
                        className={formStyles.option}
                        key={code}
                        value={code}
                      >
                        {name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <p>Payment Information</p>
            <div className={formStyles.formGroup}>
              <div className={formStyles.element}>
                <label className={formStyles.cardLabel}>Card Details</label>
                <CardElement
                  onChange={change => this.cardChanged(change)}
                  onReady={element => this.getCardElement(element)}
                  className={formStyles.input}
                  style={stripeStyles}
                />
              </div>
            </div>
            {paymentProcessing && <div className={styles.loader}></div>}

            {!paymentProcessing && (
              <>
                <Button
                  color="gold"
                  fullWidth
                  disabled={!cardCompleted || paymentProcessing}
                >
                  {!config?.settings?.isMonthTrial
                    ? `Pay ${config?.content?.currencySymbol}${(
                        (config?.settings?.billingAmount +
                          config?.settings?.vatAmount) /
                        100
                      ).toFixed(2)} (incl. VAT)`
                    : "Start Subscription"}
                </Button>

                {config?.settings?.isMonthTrial &&
                  (config?.settings?.vatAmount > 0 ? (
                    <span className={styles.subText}>
                      {`First month free thereafter ${config?.content?.currencySymbol}${(
                        config?.settings?.billingAmount / 100
                      ).toFixed(2)} + ${config?.content?.currencySymbol}${(
                        config?.settings?.vatAmount / 100
                      ).toFixed(2)} VAT, per ${
                        config?.settings.billingInterval
                      } .`}
                    </span>
                  ) : (
                    <span className={styles.subText}>
                      {`First month free thereafter ${config?.content?.currencySymbol}${(
                        config?.settings?.billingAmount / 100
                      ).toFixed(2)} per ${config?.settings.billingInterval}.`}
                    </span>
                  ))}
              </>
            )}
          </form>
        </Wrap>
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);
