import React from "react";
import Wrap from "../../../global-components/layout/wrap";
import Button from "../../../global-components/button/button";
import styles from "./example-report.module.css";
import arrow from "./images/arrow-module.png";
import riskRating from "./images/risk-rating-module.png";
import ipadReport from "./images/ipad.png";

const ExampleReport = ({ config }) => (
  <section className={styles.exampleReport}>
    <Wrap>
      <h2>{config?.content?.landing?.exampleReport?.title1}</h2>
      {config?.content?.landing?.exampleReport?.desc1.map((item, i) => (
        <p key={i}>{item}</p>
      ))}
      <div className={styles.graphic}>
        <div className={styles.container}>
          <div className={styles.arrowLeft}>
            <img
              className={styles.arrowImg}
              src={arrow}
              alt="Right pointing arrow"
            />
            <ul className={styles.scoreContent}>
              {config?.content?.landing?.exampleReport?.reportContentsLeft.map(
                (item, i) => (
                  <li key={i}>{item}</li>
                )
              )}
            </ul>
          </div>
          <div className={styles.meter}>
            <img src={riskRating} alt="Risk rating score" />
          </div>
          <div className={styles.arrowRight}>
            <img
              className={styles.arrowImg}
              src={arrow}
              alt="Left pointing arrow"
            />
            <ul className={styles.scoreContent}>
              {config?.content?.landing?.exampleReport?.reportContentsRight.map(
                (item, i) => (
                  <li key={i}>{item}</li>
                )
              )}
            </ul>
          </div>
        </div>
        <img
          className={styles.ipad}
          src={ipadReport}
          alt="iPad showing an Orpheus security report"
        />
      </div>
      <h2>{config?.content?.landing?.exampleReport?.title2}</h2>
      <p>{config?.content?.landing?.exampleReport?.desc2}</p>
      <Button url={config?.content?.landing?.exampleReport?.cta.url}>
        {config?.content?.landing?.exampleReport?.cta.text}
      </Button>
    </Wrap>
  </section>
);

export default ExampleReport;
