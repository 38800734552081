import React from "react";
import styles from "./button.module.css";

const Button = ({
  color,
  url,
  fullWidth,
  onClick,
  children,
  disabled,
  style
}) => {
  const classes = [styles.button];

  if (color === "gold") classes.push(styles.gold);
  if (color === "navy") classes.push(styles.lightNavy);
  if (fullWidth) classes.push(styles.buttonBlock);

  const classNames = classes.join(" ");

  return url ? (
    <a
      className={classNames}
      style={style ? style : {}}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  ) : (
    <button
      className={classNames}
      style={style ? style : {}}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
