import React from "react";
import Wrap from "../layout/wrap";
import Button from "../button/button";
import styles from "./checkout-complete.module.css";
import orpheusLogo from "./images/orpheus-logo.svg";
import completeIcon from "./images/icon-complete.svg";

const CheckoutComplete = ({ success, error, config }) => (
  <div className={styles.checkoutComplete}>
    <Wrap>
      <div className={styles.header}>
        <img
          className={styles.orpheusLogo}
          src={orpheusLogo}
          alt="Orpheus Logo"
        />
      </div>

      {success && (
        <div>
          <img className={styles.complete} src={completeIcon} alt="Tick Icon" />
          <h1 className={styles.title}>
            {config?.content?.checkoutComplete.success.title}
          </h1>
          <p className={styles.para}>
            {config?.content?.checkoutComplete.success.desc}
          </p>
          <Button
            color="gold"
            fullWidth
            url={config?.content?.checkoutComplete.success.primaryyButtonLink}
          >
            {config?.content?.checkoutComplete.success.primaryyButtonText}
          </Button>
          {config?.content?.checkoutComplete.success.secondaryButtonLink && (
            <Button
              color="navy"
              fullWidth
              url={
                config?.content?.checkoutComplete.success.secondaryButtonLink
              }
            >
              {config?.content?.checkoutComplete.success.secondaryButtonText}
            </Button>
          )}
        </div>
      )}

      {!success && !error && (
        <div>
          <h1 className={styles.title}>
            {config?.content?.checkoutComplete.fail.title}
          </h1>
          <p className={styles.para}>
            {config?.content?.checkoutComplete.fail.desc}
          </p>
          <Button
            color="gold"
            fullWidth
            onClick={() => window.location.reload()}
          >
            {config?.content?.checkoutComplete.fail.primaryyButtonText}
          </Button>
        </div>
      )}

      {error && (
        <div>
          <h1 className={styles.title}>
            {config?.content?.checkoutComplete.error.title}
          </h1>
          <p className={styles.para}>
            {config?.content?.checkoutComplete.error.desc}
          </p>
        </div>
      )}
    </Wrap>
  </div>
);

export default CheckoutComplete;
