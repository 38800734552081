import React from "react";
import styles from "./error-404.module.css";

const Error404 = () => {
  return (
    <div className={styles.Error404Container}>This page does not exist</div>
  );
};

export default Error404;
