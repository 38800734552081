import React from "react";
import Wrap from "../../../global-components/layout/wrap";
import Link from "../../../global-components/link/link";
import styles from "./faq.module.css";

const Faq = ({ config }) => (
  <section className={styles.faq}>
    <Wrap>
      <h2>{config?.content?.landing?.faq?.title}</h2>
      <span style={{ textAlign: "justify" }}>

        {config?.content?.landing?.faq?.questions.map(
          ({ question, answer, url, urlText }, i) => (
            <div key={i}>
              <h3>{question}</h3>
              {Array.isArray(answer) ? answer.map(answerEntries => {
                if (Array.isArray(answerEntries)) {
                  return <ul>
                    {
                      answerEntries.map(listItem => (<li>{listItem}</li>))
                    }
                  </ul>
                }
                return <p>{answerEntries}</p>
              }) : <p>{answer}</p>}
              {url && <Link href={url}>{urlText}</Link>}
            </div>
          )
        )}
      </span>
    </Wrap>
  </section>
);

export default Faq;
