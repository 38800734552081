const content = {
  basket: {
    subTitle: ""
  },
  landing: {
    hero: {
      title: [
        "Cyber Risk Rating reports to help you immediately improve your cyber security."
      ],
      content: [
        "Orpheus is the only UK government-accredited cyber threat intelligence company providing award-winning cyber risk rating services."
      ],
      contentsub: [
        "Orpheus' award-winning Cyber Risk Rating reports will enable you to improve your cyber security and demonstrate to customers how seriously you take cyber security and compliance with cyber security regulations. You will receive:"
      ],
      resources: [
        {
          content:
            "Securing your Supply Chain with Threat-led Cyber Risk Ratings",
          ctaText: "Read our White Paper",
          ctaUrl:
            "https://orpheus-cyber.com/wp-content/uploads/2020/03/Orpheus-SupplyChain-Whitepaper.pdf"
        },
        {
          content:
            "Read our latest press coverage in SC Magazine on securing your supply chain",
          ctaText: "Read the Article",
          ctaUrl:
            "https://www.scmagazineuk.com/search/articles?keywords=orpheus&headlinesOnly=False"
        }
      ]
    },
    faq: {
      questions: [
        {},
        {
          question: "Who can I contact to find out more?",
          answer: "For more information please send an email to",
          url: "mailto:contact@orpheus-cyber.com ",
          urlText: "contact@orpheus-cyber.com "
        }
      ]
    }
  }
};

export default content;
