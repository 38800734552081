import React from "react";
import serialize from "form-serialize";

import styles from "./basket.module.css";
import reportIcon from "./images/icon-report.png";
import successIcon from "./images/success.png";
import Checkout from "./checkout";
import CustomerForm from "./customerForm";
import EulaModal from "./eulaModal";
import Wrap from "../layout/wrap";

class Basket extends React.Component {
  constructor(props) {
    super(props);

    const { config } = this.props;

    this.state = {
      updatedConfig: null,
      currentStep: "basket",
      customerFormDetails: null,
      eulaIsOpen: false,
      subtotalAmount: config?.settings?.billingAmount,
      vatAmount: config?.settings?.vatAmount,
      totalAmount: config?.settings?.billingAmount + config?.settings?.vatAmount,
      isFreeSingleDownload: config?.settings?.isFreeSingleDownload,
      sendingReportRequest: false,
      requestReportSubmitted: false,
      requestReportSuccess: null // null means not requested yet
    };

    window.dataLayer.push({
      event: "landing",
      category: 'payment_funnel'
    });
  }

  onEulaClick(event) {
    event.preventDefault();

    this.setState({
      eulaIsOpen: true,
    });
  }

  onEulaClose(event) {
    event.preventDefault();

    this.setState({
      eulaIsOpen: false,
    });
  }

  async onCustomerFormSubmit(event) {
    event.preventDefault();

    const { config } = this.props;

    this.setState({ requestReportSubmitted: false, requestReportSuccess: null })

    const customerFormDetails = serialize(event.target, {
      hash: true,
    });

    if (config?.settings?.isUrlCompanyIdRequired) {
      if (
        !customerFormDetails.companyId ||
        isNaN(customerFormDetails.companyId)
      ) {
        window.alert(
          "\n It looks like there's some data missing which we need to process your payment.\n\nPlease check that you have entered the url correctly."
        );

        return;
      }
    }

    // Get checkout settings from the server
    const settingsResponse = await fetch(
      `/settings/${config.settings.product}/${config.settings.partnership}?companyId=${customerFormDetails.companyId}&countryCode=${customerFormDetails.country}`,
      {
        method: "GET",
      }
    );
    const settings = await settingsResponse.json();

    if (config?.settings?.isFreeSingleDownload) {
      this.setState({ sendingReportRequest: true })
      const reportRequestResponse = await fetch("/request-report", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          customerDetails: customerFormDetails,
          settings
        })
      })

      const requestReportSuccess = reportRequestResponse.status == 200
      const requestReportSubmitted = true
      const sendingReportRequest = false

      this.setState({ sendingReportRequest, requestReportSubmitted, requestReportSuccess })
    } else {
      this.setState({
        updatedConfig: { settings: settings, content: config.content },
      });

      this.setState({
        customerFormDetails: customerFormDetails,
        currentStep: "checkout",
      });
    }

  }

  onCheckoutBack = () => {
    this.setState({
      currentStep: "basket",
    });

    // FIXME: this doesn't work when enabled
    // window.dataLayer.push({
    //   event: "checkoutCancelled",
    // });
  };

  render() {
    const { currentStep } = this.state;

    return (
      <>
        {currentStep === "basket" && this._renderBasket()}
        {currentStep === "checkout" && this._renderCheckout()}
      </>
    );
  }

  getTitleText(config) {
    if (config?.settings?.isFreeSingleDownload) return 'Orpheus Cyber Risk Rating Report'
    else if (config?.settings?.isMonthTrial) return 'Get Your Free Trial Report Now'
    return 'Purchase Your Report'
  }

  isPriceSectionVisible(config) {
    return config?.settings?.isFreeSingleDownload
  }

  _renderBasket() {
    const { customer, config } = this.props;
    const { eulaIsOpen } = this.state;

    return (
      <div className={styles.basket}>
        <Wrap>
          <h1 className={styles.basketTitle}>
            {this.getTitleText(config)}
          </h1>
          <h3>{config?.content?.basket.subTitle}</h3>

          <div style={{ display: this.state.requestReportSubmitted && this.state.requestReportSuccess ? '' : 'none' }}>

            <div className={styles.basketItem}>
              <img
                className={styles.itemIcon}
                src={successIcon}
                alt="Report Icon"
              />
              <div className={styles.itemContent}>
                <h4 className={styles.itemTitle}>
                  Request successful.
                </h4>
                <p className={styles.itemDesc}>
                  We'll be in touch soon with more details.
                </p>
              </div>
            </div>

          </div>

          <div style={{ display: this.state.requestReportSubmitted && this.state.requestReportSuccess ? 'none' : '' }}>
            <section className={styles.basketItems}>
              <div className={styles.basketItem}>
                <img
                  className={styles.itemIcon}
                  src={reportIcon}
                  alt="Report Icon"
                />
                <div className={styles.itemContent}>
                  <h4 className={styles.itemTitle}>
                    {config?.settings?.isFreeSingleDownload ? 'Request your free company report using the form below.' : config?.content?.basket.itemTitle}
                  </h4>
                  <p className={styles.itemDesc}>
                    {config?.settings?.isFreeSingleDownload
                      ? ''
                      : config?.content?.basket.itemDesc
                    }
                  </p>
                </div>
              </div>
            </section>
            {config?.settings?.isFreeSingleDownload ? '' : (
              <ul className={styles.basketTotals}>
                {config?.settings?.isMonthTrial
                  ? this._renderFreeTrialTotal()
                  : this._renderTotal()}
              </ul>
            )}

            <CustomerForm
              {...customer}
              config={config}
              onEulaClick={(event) => this.onEulaClick(event)}
              onSubmit={(event) => this.onCustomerFormSubmit(event)}
              isFreeSingleDownload={config?.settings?.isFreeSingleDownload}
              submitDisabled={this.state.sendingReportRequest}
            />
            <h3 style={{ display: this.state.requestReportSubmitted && !this.state.requestReportSuccess ? '' : 'none' }}>
              There was a problem submitting your request, please try again later.
            </h3>
          </div>



          {eulaIsOpen ? (
            <div className={styles.eulaOpen}>
              <EulaModal onEulaClose={(event) => this.onEulaClose(event)} />
            </div>
          ) : null}
        </Wrap>
      </div>
    );
  }

  _renderFreeTrialTotal() {
    const { config } = this.props;

    return (
      <>
        { }
        <li className={styles.totals}>
          <span>First Month Total</span>
          <span>{`${config?.content?.currencySymbol}${(0).toFixed(2)}`}</span>
        </li>
        <li className={styles.subText}>
          <span>
            {`Thereafter ${config?.content?.currencySymbol}${(
              this.state.totalAmount / 100
            ).toFixed(2)} per ${config?.settings?.billingInterval === "month" ? "month" : "year"
              }, plus VAT, if applicable. Cancellable at any time before next payment is due. See terms and conditions for full details.`}
          </span>
        </li>
      </>
    );
  }

  _renderTotal = () => {
    const { config } = this.props;

    return (
      <>
        <li className={styles.totals}>
          <span>
            {config?.settings?.isSubscription
              ? config?.settings?.billingInterval === "month"
                ? "Monthly Total"
                : "Annual Total"
              : "Subtotal"}
          </span>
          <span>{`${config?.content?.currencySymbol}${(
            this.state.totalAmount / 100
          ).toFixed(2)}`}</span>
        </li>
        <li className={styles.subText}>
          <span>
            Plus VAT, if applicable. Cancellable at any time before next payment is due. See terms and conditions for full details.
          </span>
        </li>
      </>
    );
  };

  _renderCheckout = () => {
    const { updatedConfig } = this.state;
    const { customerFormDetails } = this.state;

    return (
      <div className={styles.basketOpen}>
        <Checkout
          customer={{ ...customerFormDetails }}
          config={updatedConfig}
          onCheckoutBack={this.onCheckoutBack}
        />
      </div>
    );
  };
}

export default Basket;
