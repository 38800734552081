import React from "react";
import Button from "../button/button";
import formStyles from "./form-elements.module.css";
import countryCodes from "./country-codes.json";

const CustomerForm = ({
  companyName,
  companyId,
  name,
  email,
  phone,
  onEulaClick,
  onSubmit,
  isFreeSingleDownload,
  submitDisabled
}) => (
  <form onSubmit={onSubmit}>
    <div className={formStyles.formGroup}>
      <div className={formStyles.element}>
        <label className={formStyles.label}>Company Name</label>
        <input
          className={formStyles.input}
          name="companyName"
          defaultValue={companyName}
          placeholder="Company Name"
          required
        />
      </div>
      <input type="hidden" name="companyId" value={companyId} />
      <div className={formStyles.element}>
        <label className={formStyles.label}>Operating Country</label>
        <select
          className={formStyles.input}
          name="country"
          defaultValue=""
          required
        >
          {countryCodes.map(({ code, name }) => {
            return (
              <option className={formStyles.option} key={code} value={code}>
                {name}
              </option>
            );
          })}
        </select>
      </div>
      <div className={formStyles.element}>
        <label className={formStyles.label}>Name</label>
        <input
          className={formStyles.input}
          name="name"
          defaultValue={name}
          placeholder="Jane Doe"
          required
        />
      </div>
      <div className={formStyles.element}>
        <label className={formStyles.label}>Email</label>
        <input
          className={formStyles.input}
          type="email"
          name="email"
          defaultValue={email}
          placeholder="jane.doe@company.com"
          required
        />
      </div>
      <div className={formStyles.element}>
        <label className={formStyles.label}>Phone</label>
        <input
          className={formStyles.input}
          name="phone"
          defaultValue={phone}
          placeholder="01234 567 890"
          required
        />
      </div>
    </div>
    <div className={formStyles.checkboxElement}>
      <input type="checkbox" defaultValue={false} required />
      <label className={formStyles.checkboxLabel}>
        {"I agree to the terms in the "}
        <span className={formStyles.eulaLink} onClick={onEulaClick}>
          license agreement
        </span>
      </label>
    </div>
    <Button color="gold" fullWidth disabled={submitDisabled} style={{opacity: submitDisabled ? '0.4' : '1'}}>
      {isFreeSingleDownload ? 'Request Report' : 'Proceed to Checkout'}
    </Button>
  </form>
);

export default CustomerForm;
