const content = {
  header: {
    partnershipAltTxt: "Achilles",
    partnershipLogoFileName: "achilles.png",
    partnershipUrl: "https://www.achilles.com/"
  },
  basket: {
    subTitle: ""
  },
  landing: {
    hero: {
      title: [
        'Achilles has partnered with Orpheus to help you immediately improve your cyber security.'
      ],
      options: [
        {
          text: 'Book a demo',
          url: 'https://calendly.com/andrew-mitchell-orpheus_cyber/achilles-orpheus-cyber',
          gold: false
        },
        {
          text: 'Buy now with partner discount',
          url: 'https://www.orpheus-portal.com/redeem?code=ACHORPHPARTNER&partner=achilles',
          gold: true
        }
      ],
      subtitle: [
        "<b>Ongoing monitoring is an important part in an effective cyber risk management process, which is why Achilles is now providing cyber risk ratings on suppliers and sharing them to buyers on the Achilles platform.</b>"
      ],
      content: [
        "The nature of risk is changing, and managing cyber security in supply chains is a highly complex evolving function. Your customers are looking for protection from the financial loss, reputational and compliance risk that cyber attacks and data breaches can bring from third parties. As cyber attacks pose an increasing threat to supply chains, it’s important for both buyers and suppliers to make sure their businesses are prepared.",
        "You can reduce your cyber risk by requesting your Orpheus cyber risk rating report, and actioning the highlighted vulnerabilities on your network. This will result in your overall cyber risk reducing both to your organisation, as well as those companies you work with. Simply request your report using the form on the right-hand side of the page.",
        "Immediately improve your Cyber Risk Score and use Orpheus’ reports to confirm to your clients, board and others that your organisation is proactively managing your cyber risks."
      ],
      contentsub: [
        "Orpheus' award-winning Cyber Risk Rating reports will enable you to improve your cyber security and demonstrate to customers how seriously you take cyber security and compliance with cyber security regulations. You will receive:"
      ],
      resources: [
        {
          content:
            "Securing your Supply Chain with Threat-led Cyber Risk Ratings",
          ctaText: "Read our White Paper",
          ctaUrl:
            "https://orpheus-cyber.com/wp-content/uploads/2021/05/19029_WP_SupplyChain_V10.pdf"
        }
      ],
      cta: null
    },
    faq: {
      questions: [
        {
          question: "What is this score telling me?",
          answer: "Our Cyber Risk Ratings indicate the level of cyber risk associated with an organisation. The higher the score, the higher risk a company faces of being the victim of a successful attack."
        },
        {
          question: "How is my score calculated?",
          answer: [
            "The score uses a large number of data points, combined with machine learning to calculate the score. The information includes:",
            [
              "Threat intelligence on sectors and countries in which you operate",
              "Unpatched vulnerabilities",
              "Evidence of weak email security processes",
              "Failures in cyber hygiene"
            ]
          ]
        },
        {
          question: "How do you know it is accurate?",
          answer: ["The tools and approach we use follows the processes used by threat actors. Based on our extensive threat intelligence experience we know what they are looking for and what they will try to exploit. Our machine learning has been peer-reviewed and is at least 94% accurate when predicting future threats.",
            "We use a thorough process of manual review to identify false positives that may create an artificially high score for an organisation. We have a process for organisations to remove any results that we can validate as incorrect, but this is incredibly rare. Some risk rating companies allow organisations to update their scores if they mitigate their issues, we will only do this with validation which ensures the accuracy of our results."
          ]
        },
        {
          question: "What is the difference between the Orpheus cyber risk management approach and frameworks such as Cyber Essentials?",
          answer: ["Cyber Essentials certification is a Government backed scheme that is designed to try and help you to protect your organisation from common cyber attacks. Cyber Essentials is self-assessed and Cyber Essentials Plus requires an accredited consultant verifying your security measures. Whilst it encourages all the correct behaviour, both are a point-in-time validation that doesn’t help with the ongoing assessment of the threats and vulnerabilities your organisation can encounter throughout the year.",
            "Orpheus provides an ongoing understanding of your threats and attack surface so that you can stop cyber risk before it happens. Cyber risk changes constantly as the cyber threat landscape shifts due to hacker’s activities and your attack surface evolves depending on the configuration of your IT assets. Orpheus’ monthly reports therefore deliver the understanding you need of both cyber threats to you and your online attack surface so that you can make your cyber risk low and keep it there."
          ]
        },
        {
          question: "Can Achilles Buyers see my Cyber Risk Rating?",
          answer: "Yes – Similar to a credit rating score, Achilles Buyers have access to the Cyber Risk Rating score in the Achilles platform."
        },
        {
          question: "After implementing the recommendations in the report, how long before my score improves?",
          answer: "Most scores update within a few days. It is possible for scores to take up to four weeks to update. If you need an accurate timeline for your individual company, please get in touch."
        },
        {
          question: "Why am I now seeing this score when I log into Achilles platform?",
          answer: "Achilles has recently partnered with Orpheus to provide Cyber Risk Ratings. Many attacks now start with a company in the supply chain, rather than by directly targeting the end victim. With the significant rise in working from home, the attack surface for criminals has grown. Attackers are aware of this and look to take advantage of suppliers with weaker security measures. To help understand and reduce the cyber risk in the supply chain, Achilles is sharing these Cyber Risk Ratings with the suppliers and the buyers in the platform."
        },
        {
          question: "What will I receive?",
          answer: "You will receive your Orpheus Cyber Risk Rating report emailed to your designated email address, together with a spreadsheet detailing your live vulnerabilities."
        },
        {
          question: "What if a supplier disagrees with their score?",
          answer: "Any organisation that disagrees with their score can contact us directly to discuss remediation. We work hard to remove false positives from our scores and an error rarely occurs. The overall Cyber Risk Rating is made from many data points so it is unlikely that a false positive in one area has a large impact on the overall score. In the unlikely event this is the case, we have a remediation process to correct this, where we can validate the error a company has identified."
        },
        {
          question: "Who can I contact to find out more?",
          answer:
            "For more information, or if you would like to contact us directly, please send an email to",
          url: "mailto: achilles@orpheus-cyber.com",
          urlText: "achilles@orpheus-cyber.com"
        }
      ]
    }
  },
  trackingIds: {
    linkedIn: {
      partnerId: "3201228"
    }
  }
};

export default content;