const content = {
  header: {
    partnership: "Orpheus Cyber in partnership with",
    partnershipAltTxt: "",
    partnershipLogoFileName: "",
    partnershipUrl: "#",
  },
  basket: {
    title: "Purchase Your Report",
    subTitle: "",
    itemTitle: "Your cyber security management reports",
    itemDesc:
      "A {{billingInterval}} subscription to receive 1 report each month.",
  },
  checkout: {},
  checkoutComplete: {
    success: {
      title: "Thank you for your order.",
      desc:
        "Your subscription has been successful. A receipt has been sent to your email address and you will shortly receive a link to download your Cyber Risk Rating Summary Report.",
      primaryyButtonText: "Visit orpheus-cyber.com",
      primaryyButtonLink: "https://orpheus-cyber.com/",
      secondaryButtonText: "",
      secondaryButtonLink: "",
    },
    fail: {
      title: "Payment Unsuccessful",
      desc:
        "We're sorry something went wrong processing your payment. Please try again or contact your card issuer for further information.",
      primaryyButtonText: "Try Again",
    },
    error: {
      title: "Something went wrong!",
      desc: "Sorry we've encountered an error. Please try again later.",
    },
  },
  landing: {
    hero: {
      // rendered as HTML
      title: [""],
      // rendered as HTML
      content: [""],
      // rendered as HTML
      contentsub: [""],
      // rendered as HTML
      bullets: [
        "Anytime, anywhere access to the Orpheus portal to see your live cyber risk report, which clearly sets out your cyber threats and live vulnerabilities",
        "Option to download the spreadsheet detailing your live vulnerabilities and where on your internet facing systems they are, so that you can take immediate steps to improve your cyber risk rating score",
        "Option to download the monthly cyber risk summary report",
        "The option of a range of further cyber risk management tools to reduce the probability of a breach",
      ],
      cta: {
        text: "Find Out More",
        url: "https://orpheus-cyber.com/thirdpartysupplychainriskmanagement-2/",
      },
      resources: null,
    },
    exampleReport: {
      title1: "The Orpheus Cyber Risk Score",
      desc1: [
        "Orpheus is the leading cyber threat intelligence and cyber risk rating company trusted by major organisations worldwide.",
        "The Orpheus Cyber Risk Score combines our deep understanding of the threat to any company with a detailed insight into the live vulnerabilities the organisation is showing that hackers will try to exploit.",
        "This is why the Orpheus Cyber Risk Score is acknowledged as both accurate and immediately actionable, and is used by government departments, retailers and major companies in every sector as the benchmark for understanding the cyber risk of their third parties.",
      ],
      reportContentsLeft: [
        "Intelligence of intent to target your technologies",
        "Cyber threat activity against your sector",
        "Evidence of adversaries exploiting live vulnerabilities",
        "Intelligence of threat actor activity in countries where you operate",
        "Dark web mentions related to you",
      ],
      reportContentsRight: [
        "Security posture of web-facing infrastructure",
        "Evidence of potentially compromised networks",
        "Vulnerabilities seen on your technology",
        "Failures in your cyber hygiene",
        "Breached employee passwords and email addresses",
      ],
      title2:
        "Immediately improve your Cyber Risk Score and use Orpheus’ reports to confirm to your clients, board and others that your organisation is proactively managing your cyber risks.",
      desc2: "",
      cta: {
        text: "View a Sample Report",
        url:
          "https://orpheus-cyber.com/wp-content/uploads/2020/03/Acme-Inc-Sample-Report.pdf",
      },
    },
    faq: {
      title: "Frequently Asked Questions",
      questions: [
        {
          question: "What will I receive?",
          answer:
            "Following payment you will receive your first Orpheus Cyber Risk Rating report emailed to your designated email address, together with a spreadsheet detailing your live vulnerabilities. You will then receive updated reports on a monthly basis from your commencement date.",
        },
      ],
    },
  },
};

export default content;
