import React from "react";
import Wrap from "../../../global-components/layout/wrap";
import Button from "../../../global-components/button/button";
import styles from "./hero.module.css";

const Hero = ({ config }) => (
  <section className={styles.hero}>
    <Wrap>
      <h1 style={{paddingBottom: config?.content?.landing?.hero?.options ? '0' : '2em'}}>
        {config?.content?.landing?.hero?.title.map((text, i) => (
          <span key={i} dangerouslySetInnerHTML={{ __html: text + "<br />" }} />
        ))}
      </h1>

      {config?.content?.landing?.hero?.options?.map(({text, url, gold}) => (
        <Button style={{marginLeft: '20px', marginRight: '20px', marginTop: '0'}} color={gold ? "gold" : 'blue'} url={url} target="_blank" rel="noopener noreferrer">
          {text}
        </Button>
      ))}

      <div style={{ paddingBottom: "2.67em" }}>
        {config?.content?.landing?.hero?.subtitle && config?.content?.landing?.hero?.subtitle.map((text, i) => (
          <p
            style={{ fontSize: "26px" }}
            key={i}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        ))}
        {config?.content?.landing?.hero?.content.map((text, i) => (
          <p
            style={{ fontSize: "20px" }}
            key={i}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        ))}
      </div>

      <div className={styles.ctaExampleReport}>
        <Button url={config?.content?.landing?.exampleReport?.cta.url}>
          {config?.content?.landing?.exampleReport?.cta.text}
        </Button>
      </div>

      {config?.content?.landing?.hero?.contentsub.map((text, i) => (
        <p style={{ textAlign: "left", paddingBottom: "10px" }} key={i}>
          {text}
        </p>
      ))}

      <ul style={{ listStylePosition: "outside" }}>
        {config?.content?.landing?.hero?.bullets.map((text, i) => (
          <li
            className={styles.bullets}
            key={i}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        ))}
      </ul>

      <div>
        {config?.settings?.isMonthTrial && (
          <p style={{ fontSize: "20px", fontWeight: "bolder" }}>
            Start your Exclusive Free Trial Today
          </p>
        )}
      </div>

      <span style={{ display: config?.content?.landing?.hero?.cta?.url ? '' : 'none' }}>
        <Button url={config?.content?.landing?.hero?.cta?.url}>
          {config?.content?.landing?.hero?.cta?.text}
        </Button>
      </span>

      {config?.content?.landing?.hero?.resources && (
        <div className={styles.resourceContainer}>
          {config?.content?.landing?.hero?.resources.map((resource, i) => (
            <div className={styles.resource} key={i}>
              <p
                className={styles.resourceContent}
                style={{}}
                dangerouslySetInnerHTML={{ __html: resource.content }}
              />
              <Button
                color={"blue"}
                url={resource.ctaUrl}
                style={{
                  marginTop: "0",
                  display: "inline-block",
                }}
              >
                {resource.ctaText}
              </Button>
            </div>
          ))}
        </div>
      )}
    </Wrap>
  </section>
);

export default Hero;
