const content = {
  header: {
    partnershipAltTxt: "2 Sec",
    partnershipLogoFileName: "2-sec.png",
    partnershipUrl: "https://2-sec.com/",
  },
  checkoutComplete: {
    success: {
      secondaryButtonText: "Visit 2 Sec",
      secondaryButtonLink: "https://2-sec.com/",
    },
  },
  landing: {
    hero: {
      title: [
        "Cyber Risk Rating reports to help you immediately improve your cyber security.",
      ],
      content: [
        "Orpheus is the only UK government-accredited cyber threat intelligence company providing award-winning cyber risk rating services.",
        "2|SEC Consulting deliver tailored cyber security and information security solutions to protect our clients’ brands and reputations. Our services are designed to ensure that our clients’ most important assets are protected, available and continuously operating. We are committed to providing outstanding support and delivering on all aspects of cyber and information security. Our services are adapted to the exact business needs of each client to achieve their organisations’ objectives.",
      ],
      contentsub: [
        "Orpheus' award-winning Cyber Risk Rating reports will enable you to improve your cyber security and demonstrate to customers how seriously you take cyber security and compliance with cyber security regulations.",
      ],
      resources: [
        {
          content:
            "2-Sec is offering a 3 month complimentary service providing email phishing protection from Aquilai, end point isolation from Menlo and patching and probing solution from Qualys.",
          ctaText: "Register Here",
          ctaUrl: "https://www.2-sec.com/covid19/",
        },
        {
          content:
            "Securing your Supply Chain with Threat-led Cyber Risk Ratings",
          ctaText: "Read our White Paper",
          ctaUrl:
            "https://orpheus-cyber.com/wp-content/uploads/2020/03/Orpheus-SupplyChain-Whitepaper.pdf",
        },
        {
          content:
            "Read our latest press coverage in SC Magazine on securing your supply chain",
          ctaText: "Read the Article",
          ctaUrl:
            "https://www.scmagazineuk.com/search/articles?keywords=orpheus&headlinesOnly=False",
        },
      ],
    },
    faq: {
      questions: [
        {},
        {
          question: "Who can I contact to find out more?",
          answer: "For more information please send an email to",
          url: "mailto:contact@orpheus-cyber.com ",
          urlText: "contact@orpheus-cyber.com ",
        },
      ],
    },
  },
};

export default content;
