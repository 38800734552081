import React from "react";
import styles from "./header.module.css";
import orpheusLogo from "./images/orpheus-logo.svg";

const Header = props => {
  const { config } = props;

  return (
    <header className={styles.header}>
      <div className={styles.headerColumn}>
        <a
          href="https://orpheus-cyber.com/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img className={styles.orpheusLogo} src={orpheusLogo} alt="Orpheus" />
        </a>
      </div>

      {config?.settings?.partnership &&
        config?.settings?.partnership !== "orpheus" && (
          <div className={styles.headerRight}>
            <p>{config?.content?.header.partnership}</p>
            <a
              href={config?.content?.header.partnershipUrl}
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                className={styles.partnershipLogo}
                src={require(`./images/${config?.content?.header.partnershipLogoFileName}`)}
                alt={config?.content?.header.partnershipAltTxt}
              />
            </a>
          </div>
        )}
    </header>
  );
};

export default Header;
