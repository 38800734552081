const content = {
  header: {
    partnershipAltTxt: "The Edge 4 Health",
    partnershipLogoFileName: "edge-health.png",
    partnershipUrl: "https://www.edge4health.co.uk/"
  },
  basket: {
    subTitle:
      "This introductory pricing offer is at a significant discount, is time limited and is for Suppliers to the NHS only."
  },
  checkoutComplete: {
    success: {
      secondaryButtonText: "Visit The Edge4Health",
      secondaryButtonLink: "https://www.edge4health.co.uk/"
    }
  },
  landing: {
    hero: {
      title: [
        "The Edge has partnered with Orpheus to help",
        "you immediately improve your cyber security."
      ],
      content: [
        "Orpheus is the only UK government-accredited cyber threat intelligence company providing award-winning cyber risk rating services."
      ],
      contentsub: [
        "Orpheus' award-winning Cyber Risk Rating reports will enable you to improve your cyber security and become a more attractive supplier. You will receive:"
      ]
    },
    faq: {
      questions: [
        {},
        {
          question: "Who can I contact to find out more?",
          answer:
            "For more information, or if you would like to contact us directly, please send an email to",
          url: "mailto:edge4health@orpheus-cyber.com",
          urlText: "edge4health@orpheus-cyber.com"
        }
      ]
    }
  }
};

export default content;
