import { getContent } from "./config";

export const currencySymbolMapper = {
  gbp: "£",
  usd: "$"
};

export const getConfig = async (product, partnership, companyId = null) => {
  // Get checkout settings from the server
  const settingsResponse = await fetch(
    `/settings/${product}/${partnership}?companyId=${companyId}`,
    {
      method: "GET"
    }
  );
  const settings = await settingsResponse.json();

  // Get content from the frontend config folder
  const content = getContent(product, partnership);

  let config = undefined;

  if (settings && content) {
    window.dataLayer.push({
      product: settings.productPrettyName,
      partnership: settings.partnershipPrettyName
    });

    // Update content values
    content.currencySymbol = currencySymbolMapper[settings.billingCurrency];
    content.basket.itemDesc = content.basket.itemDesc.replace(
      "{{billingInterval}}",
      settings.billingInterval === "month" ? "monthly" : "yearly"
    );

    // Merge settings and content into a config object
    config = {
      settings: settings,
      content: content
    };
  }

  return config;
};
