import React from "react";
import styles from "./link.module.css";

const Link = ({ href, children }) => (
  <a
    className={styles.link}
    href={href}
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </a>
);

export default Link;
